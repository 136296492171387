<template>
  <button
    v-if="copyableText"
    class="btn-action copy-text"
    @click="copyToClipboard"
  >
    <textarea
      ref="copyInput"
      v-model="copyableText"
      type="text"
    />
    <span>{{ $t('button[\'copy to clipboard\']') }}</span>
    <i class="material-icons">content_copy</i>
  </button>
</template>

<script>
export default {
  name: 'CopyToClipboard',
  props: {
    copyableText: {
      type: String,
      default: '',
    },
  },

  methods: {
    copyToClipboard() {
      this.$refs.copyInput.select();
      document.execCommand('copy');
      this.$message({
        message: this.$t('button[\'Copied to clipboard!\']'),
        type: 'success',
      });
    },
  },
};
</script>

<style lang="scss">
  .copy-text {
    height: 0.42rem;
    border: 1px solid;
    padding: 0.12rem 0.16rem;
    font-size: 0.14rem;
    color: $text-blue;
    background: transparent;
    display: inline-flex;

    i {
      margin-left: .1rem;

      @include mobile {
        font-size: 0.28rem;
      }
    }

    textarea {
      position: absolute;
      z-index: -100;
      top: -100vh;
    }
  }
</style>
