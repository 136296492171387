<template>
  <h5 class="step-title">
    <span
      v-if="step > 0"
      class="step-title__primary-text"
    >{{ $t('services.step', [step]) }}</span>
    <span class="step-title__secondary-text"> {{ title }}</span>
    <div class="step-subtitle">
      <h6
        v-if="subtitle"
        class="step-title__subtitle"
      >
        {{ subtitle }}
      </h6>
      <template v-if="subtitleExtra">
        <span
          class="subtitle-extra"
          @click="subtitleExtraClick"
        >
          <span>{{ subtitleExtra }}</span>
          <img
            :src="svg.ArrowRightGreen"
            alt="ArrowRightGreen"
          >
        </span>
      </template>
    </div>
  </h5>
</template>

<script>
import ArrowRightGreen from '@/assets/images/icons/arrow-right-green.svg';

export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    subtitleExtra: {
      type: String,
      default: '',
    },
    subtitleExtraClick: {
      type: Function,
      default: () => undefined,
    },
  },
  computed: {
    svg: () => ({
      ArrowRightGreen,
    }),
  },
};
</script>

<style lang="scss" scoped>
$color: #31A83E;
$color-bg: #34A936;
$bg-opacity: 0.1;

.step-title {
  font-size: 0.18rem;
  line-height: 0.22rem;

  &__primary-text {
    font-weight: 300;
  }

  &__secondary-text {
    font-weight: 600;
  }

  .step-subtitle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0.02rem 0 0.1rem;
  }

  &__subtitle {
    font-size: 0.14rem;
    line-height: 0.21rem;
    color: $text-primary;
    margin-bottom: 0;
    flex: 1;
    white-space: nowrap;
  }
}

.subtitle-extra {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  cursor: pointer;
  padding: 0.04rem 0.1rem 0.04rem 0.12rem;
  margin-top: 0.05rem;
  margin-right: 0.09rem;
  border-radius: 0.04rem;
  color: $color;
  background: rgba($color-bg, $bg-opacity);

  h6 {
    flex: 1;
  }

  img {
    margin-left: 0.09rem;
  }
}
</style>
