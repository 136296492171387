<template>
  <div class="generated-result">
    <step-heading
      :step="step"
      :title="$t('services.generatedResult.title')"
      :subtitle="$t('services.generatedResult.subtitle', [serviceName])"
      :subtitle-extra="subtitleExtra"
      :subtitle-extra-click="subtitleExtraClick"
    />
    <div
      v-loading="loading"
      class="generated-result__preview"
    >
      <div class="wrapper">
        <slot>
          <div class="generated-result__placeholder">
            <img
              :alt=" $t('services.generatedResult.placeholder')"
              src="@/assets/images/icons/placeholder.svg"
              class="generated-result__placeholder-icon"
            >
            <div class="generated-result__placeholder-text">
              {{ $t('services.generatedResult.placeholder') }}
            </div>
          </div>
        </slot>
      </div>
    </div>

    <div class="generated-result__note">
      <copy-to-clipboard :copyable-text="copyableText" />
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/pages/AIServices/components/StepHeading/Index.vue';
import CopyToClipboard from '@/components/CopyToClipboard/Index.vue';

export default {
  name: 'GeneratedResult',
  components: { StepHeading, CopyToClipboard },
  props: {
    serviceName: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    copyableText: {
      type: String,
      default: '',
    },
    subtitleExtra: {
      type: String,
      default: '',
    },
    subtitleExtraClick: {
      type: Function,
      default: () => undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.generated-result {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__preview {
    background-color: $background-secondary;
    flex: 1;
    border-radius: 0.04rem;
    overflow: hidden;
    position: relative;
    @include tablet-and-less {
      min-height: 4rem;
    }

    .wrapper {
      padding: 0.2rem;
      position: absolute;
      height: 100%;
      width: 100%;
      overflow: scroll;
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &-icon {
      width: 1rem;
      margin-bottom: 0.2rem;
    }

    &-text {
      color: $text-gray;
      font-size: 0.14rem;
      line-height: 0.21rem;
    }
  }

  &__note {
    margin-top: 0.2rem;
    font-size: 0.14rem;
    line-height: 0.17rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .copy-text {
      margin-left: auto;
    }
  }
}
</style>
