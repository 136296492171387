<template>
  <div class="login-promo">
    <div class="title">
      {{ $t(`services.loginPromo.title['${type}']`) }}
    </div>
    <div class="links">
      <router-link
        to="/contact-us"
        class="contact-text"
      >
        {{ $t('services.loginPromo.contactLink') }}
      </router-link>
      <template v-if="type !== 'video'">
        <span>{{ $t('services.loginPromo.or') }}</span>
        <a
          href="#"
          class="login-link"
          @click="onLoginClick"
        >
          {{ $t('services.loginPromo.loginLink') }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoginPromo',
  props: {
    type: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'audio', 'image', 'video'].indexOf(value) !== -1,
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    async onLoginClick(event) {
      event.preventDefault();
      if (!this.isAuthenticated()) {
        event.stopPropagation();
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$purchase-color: #31A83E;
$purchase-bg: #34A936;
$purchase-bg-opacity: 0.1;

.login-promo {
  margin-top: 0.26rem;
  padding: 0.16rem;
  border: 1px dashed $text-blue;
  border-radius: 0.04rem;

  .title {
    margin-bottom: 0.1rem;
    font-weight: 600;
    font-size: 0.18rem;
    line-height: 0.22rem;
  }

  .links {
    font-size: 0.14rem;
    line-height: 0.24rem;
  }

  .contact-text {
    display: inline-block;
    padding: 0 0.1rem;
    margin-right: 0.09rem;
    border-radius: 0.04rem;
    color: $purchase-color;
    background: rgba($purchase-bg, $purchase-bg-opacity);
  }

  .login-link {
    color: $link;
  }
}
</style>
