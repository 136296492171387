<template>
  <el-button
    class="run-button"
    :loading="loading"
    :disabled="disabled"
    @click="onClick"
  >
    <slot
      v-if="hasContentSlot"
      name="content"
    />
    <template v-else>
      {{ displayText || $t('button.run') }}
    </template>
  </el-button>
</template>

<script>
export default {
  name: 'RunButton',
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    displayText: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasContentSlot() {
      return !!this.$slots.content;
    },
  },
};
</script>

<style lang="scss" scoped>
  .run-button {
    display: block;
    width: 100%;
    margin-top: 0.16rem;
    border: none;
    color: white;
    background: $background-primary;
    font-weight: 600;

    &[disabled] {
      color: white;
      background: $background-disabled;
    }
  }
</style>
