<template>
  <service-container>
    <service-info
      :icon="$props.icon"
      :service="$props.service"
    >
      <template slot="header">
        <slot name="header" />
      </template>
      <template slot="subheader">
        <slot name="subheader" />
      </template>
    </service-info>
    <service-content>
      <template slot="left">
        <slot name="left" />
        <run-button
          v-if="run"
          :on-click="onClick"
          :disabled="runDisabled"
          :loading="loading"
          :display-text="runDisplayText"
        >
          <template
            v-if="hasRunSlot"
            slot="content"
          >
            <slot name="run" />
          </template>
        </run-button>
        <login-promo
          v-if="!isAuthenticated()"
          :type="type"
        />
      </template>
      <template slot="right">
        <slot name="right" />
      </template>
    </service-content>
  </service-container>
</template>

<script>
import RunButton from '@/views/pages/AIServices/components/RunButton/Index.vue';
import LoginPromo from '@/views/pages/AIServices/components/LoginPromo/Index.vue';
import ServiceContainer from '@/views/pages/AIServices/components/ServiceTemplate/Container.vue';
import ServiceInfo from '@/views/pages/AIServices/components/ServiceTemplate/Info.vue';
import ServiceContent from '@/views/pages/AIServices/components/ServiceTemplate/Content.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ServiceTemplate',
  components: {
    LoginPromo,
    RunButton,
    ServiceContainer,
    ServiceInfo,
    ServiceContent,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    service: {
      type: String,
      required: true,
      validator: (value) => ['nlp', 'vca', 'aiu', 'asa', 'lg'].indexOf(value) !== -1,
    },
    run: {
      type: Function,
      default: null,
    },
    runSuccess: {
      type: Function,
      default: () => {
      },
    },
    runError: {
      type: Function,
      default: () => {
      },
    },
    runDisplayText: {
      type: String,
      default: '',
    },
    runDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => ['text', 'audio', 'image', 'video'].indexOf(value) !== -1,
    },
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    hasRunSlot() {
      return !!this.$slots.run;
    },
  },
  methods: {
    ...mapActions(['storeErrors']),
    async onClick() {
      try {
        await this.runSuccess(await this.run());
      } catch (e) {
        this.storeErrors(e);
        this.runError();
      }
    },
  },
};
</script>
